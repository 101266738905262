(function () {
	'use strict';

	angular
		.module('app')
		.directive('notificationsResize', [
			'$rootScope',
			'$timeout',
			notificationsResize,
		])
		.directive('notificationsClosed', [notificationsClosed]);

	function notificationsResize($rootScope, $timeout) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				$rootScope.$on('resize', adjustNotificationList);
				scope.$on('notificationsUpdated', adjustNotificationList);

				function adjustNotificationList() {
					$timeout(function () {
						resizeElement();
						notificationsAddScroll();
					}, 0);
				}

				function resizeElement() {
					var elementPositionTop =
						element[0].getBoundingClientRect().top;
					var elementHeight =
						window.innerHeight - elementPositionTop - 110;
					element[0].style.maxHeight = elementHeight + 'px';
				}

				function notificationsAddScroll() {
					//Add a set timeout here as we wan't to make sure the element is fully rendered but we don't need another digest cycle
					window.setTimeout(function () {
						element.nanoScroller();
					}, 0);
				}
			},
		};
	}

	function notificationsClosed() {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				element.on('hide.bs.dropdown', notificationsHidden);

				function notificationsHidden() {
					scope.notificationMenuClosed();
				}
			},
		};
	}
})();
